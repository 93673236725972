@tailwind base;
@tailwind components;
@tailwind utilities;

#root,
body,
html {
    height: 100%;
    width: 100%;

    background-color: #0f0f0f;
    @apply text-white select-none;
}

.auth_form {
    @apply space-y-4 md:space-y-6;
}

.auth_form .label {
    @apply block mb-2 text-sm font-medium text-white;
}

.auth_form .input {
    @apply border sm:text-sm rounded-lg block w-full p-2.5 bg-gray-800 border-gray-700 focus:outline-none placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500;
}

.auth_form .check_box {
    @apply w-4 h-4 border rounded bg-gray-700 border-gray-600 ring-offset-gray-800;
}

.auth_form .button {
    @apply w-full disabled:bg-primary-900 disabled:w-16 overflow-hidden whitespace-nowrap transition-width ease-in-out duration-200 mx-auto text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-primary-600 hover:bg-primary-700 focus:ring-primary-800;
}

.auth_form .loading {
    @apply grid place-items-center;
}

.version {
    @apply fixed bottom-0 left-0 text-xs text-gray-500 p-2;
}
